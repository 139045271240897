<template>

	<div class="section-account-info container py-6">

		<div class="row justify-content-center">
			<div class="col-sm-11 col-md-9 col-lg-8 col-xl-7 pt-4">
				<h2 class="mb-4">Hey <span class="text-capitalize text-black">{{ auth.name.split(' ').shift() }}</span> 👋 welcome to Domains App</h2>

				<p class="lead">Thanks for creating an account, we hope this will be a helpful tool to search, organize and monitor domains.</p>
				<p class="lead">
					Please confirm your email so we can unlock all functionality for your account.
					We just sent you a confirmation link to <code>{{ auth.email }}</code>.
				</p>
				<p v-if="auth.email.endsWith('@gmail.com')"><a href="https://mail.google.com/mail/#search/from%3Ahello%40dmns.app" target="_blank" class="btn btn-outline-dark">Open Gmail →</a></p>
				<p v-else-if="auth.email.includes('@hotmail.') || auth.email.includes('@outlook.') || auth.email.includes('@live.')"><a href="https://outlook.live.com/mail/" target="_blank" class="btn btn-outline-dark">Open Outlook →</a></p>
				<p v-else-if="auth.email.includes('@yahoo.')"><a href="https://mail.yahoo.com/" target="_blank" class="btn btn-outline-dark">Open Yahoo →</a></p>

				<p class="lead mb-5">And here are some suggestions for what you can do next:</p>

				<h5>🕵️ View domain infos or monitor for changes</h5>
				<p class="mb-1">These are some you searched recently:</p>
				<div class="row row-cols-2 row-cols-lg-3 gx-3 mb-4">
					<div v-for="domain in recentDomains.slice(0, 6)" :key="`dm-monitor-${domain}`" class="col my-2">
						<router-link :to="`/domains?q=${domain}`" class="card card-link">
							<div class="card-body">
								<h6 class="card-title my-0">{{ domain }}</h6>
							</div>
						</router-link>
					</div>
					<div v-if="!recentDomains.length" class="col my-2">
						<div class="card bg-light border" style="border-style: dashed !important">
							<div class="card-body">
								<span>Search now for any word or brand</span>
							</div>
						</div>
					</div>
				</div>

				<h5>🗃 Browse domain collections</h5>
				<p class="mb-1">These are some you searched recently:</p>
				<div class="row row-cols-1 row-cols-lg-2">
					<div v-for="list in publicLists.slice(0, 4)" :key="`list-${list.id}`" class="col my-2">
						<list-card :list="list" />
					</div>
				</div>
				<p class="mb-4"><router-link to="/collections">View more →</router-link></p>

				<h5>🧩 Check out the {{ browserName }} extension</h5>
				<p><router-link to="/browser-extension">Get domains info as you browse the internet →</router-link></p>

			</div>
		</div>

	</div>

</template>

<script>
import { mapGetters, mapState } from 'vuex'

import ListCard from '@/components/ListCard.vue'

export default {
	name: 'AccountInfo',
	components: { ListCard },
	data() {
		return {
			recentDomains: JSON.parse(localStorage.getItem('domains') || '[]')
		}
	},
	created() {
		this.$store.dispatch('loadPublicLists')
	},
	computed: {
		...mapGetters([ 'auth' ]),
		...mapState([ 'account', 'publicLists' ]),
		browserName() {
			const isFirefox = navigator.userAgent.toLowerCase().includes('firefox')
			const isEdge = navigator.userAgent.toLowerCase().includes('edg/') || navigator.userAgent.toLowerCase().includes('edge')
			const isChrome = !isEdge && window.chrome && navigator.vendor === 'Google Inc.'

			if (isFirefox) {
				return 'Firefox'
			} else if (isEdge) {
				return 'Edge'
			} else if (isChrome) {
				return 'Chrome'
			} else {
				return 'browser'
			}
		},
	},
	methods: {
		
	}
}
</script>
