<template>

	<section class="my-5 pt-4">

		<div class="container">

			<div class="row align-items-center">
				<div class="d-none d-md-flex col-md-7 px-5">
					<img src="@/assets/img-account.png" alt="Account" style="width: 70%">
				</div>

				<div class="col-md-5">

					<form @submit.prevent="resetPass" class="bg-white rounded shadow-sm p-4">
						<h3>Forgotten password</h3>
						<p class="mb-4"><router-link to="/account/sign-in">← Sign in</router-link></p>

						<div v-if="info" class="alert alert-info" role="alert">{{ info }}</div>
						<p v-else>We will send you an email with instructions for changing your password. Please enter your email address below.</p>

						<div class="mb-3">
							<label class="form-label" for="email">Email</label>
							<input type="email" class="form-control" id="email" v-model="account.email" placeholder="Your email" required>
						</div>

						<div class="row align-items-center">
							<div class="col-auto">
								<button class="btn btn-primary" :disabled="state === 'loading'">Continue</button>
							</div>
						</div>
					</form>
					
				</div>
			</div>

		</div>
		
	</section>

</template>

<script>
import domainsApi from "@/api.js"
import Vue from 'vue'

export default {
	name: 'AccountResetPass',
	data() {
		return {
			state: 'idle',
			account: {
				email: '',
			},
			info: '',
		}
	},
	methods: {
		resetPass() {
			this.state = 'loading'

			domainsApi.post('account/reset-pass', this.account)
				.then(() => {
					this.state = 'idle'
					this.info = 'If a account exists for that email address, a message will be sent with further instructions. You may need to check your spam folder or unblock "support@dmns.app"'
				})
				.catch(error => {
					this.state = 'error'
					Vue.toasted.show(error.message, { type: 'error' })
				})
		},
	},
}
</script>
