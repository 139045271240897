<template>

	<section class="my-6 pt-5">

		<div class="container">

			<div class="row align-items-center">
				<div class="d-none d-lg-block col-md-6 col-lg-7 px-5">
					<div class="rounded bg-cyan-100 py-3 px-4 mb-5">
						<div class="row align-items-center">
							<div class="col-auto pe-0">
								<img src="/images/logo-512.png" width="100" alt="Domains App logo" style="bottom: -16px;position: relative;left: -24px;border-bottom-left-radius: 0.25rem;" />
							</div>
							<div class="col">
								<h3>Monitor a domain for free</h3>
								<p class="mb-0">Create a FREE account and start monitoring a domain, there's no plan required</p>
							</div>
						</div>
					</div>

					<router-link to="/plans" class="rounded bg-teal-100 d-block py-2 px-4">
						<div class="row align-items-center">
							<div class="col-auto">
								<img src="/images/plan-business.png" width="100" alt="Domains App Business" />
							</div>
							<div class="col">
								<h3 class="text-dark">Pick a plan later</h3>
								<p class="mb-0 text-dark">Check out the plans for monitoring more domains</p>
							</div>
						</div>
					</router-link>
				</div>

				<div class="col-lg-5">
					<form @submit.prevent="register" class="bg-white rounded shadow-sm p-4">

						<h3>Create account</h3>
						<p class="mb-4">or <router-link to="/account/sign-in">sign in</router-link></p>

						<div v-if="error" class="alert alert-warning" role="alert" v-html="error"></div>

						<div v-if="$route.query.plan && plans[$route.query.plan]" class="border rounded p-2 mb-3">
							<h4 class="float-end mt-2">${{ plans[$route.query.plan].price }}<small class="text-muted">/month</small></h4>

							<h5 class="subtitle">{{ plans[$route.query.plan].name }} plan <router-link to="/plans" class="ms-2 text-lowercase"><small>change plan</small></router-link></h5>
							<p class="text-muted mb-0"><small>14 day trial, no credit card required</small></p>
						</div>

						<div class="mb-3">
							<label class="form-label" for="name">Name</label>
							<input type="text" class="form-control" id="name" required placeholder="Your name" v-model="account.name" >
						</div>
	
						<div class="mb-3">
							<label class="form-label" for="email">Email</label>
							<input type="email" class="form-control" id="email" required placeholder="Your email" v-model="account.email" >
						</div>

						<div class="mb-3">
							<label class="form-label" for="password">Password</label>
							<input type="password" class="form-control" id="password" required minlength="6" placeholder="Your password" v-model="account.password">
						</div>

						<div class="mb-3">
							<div class="form-check">
								<input class="form-check-input" type="checkbox" id="terms" name="terms" required>
								<label class="form-check-label" for="terms">I agree to the <router-link to="/policies/terms">Domains App Terms</router-link></label>
							</div>
						</div>

						<p class="mb-0"><button class="btn btn-primary">Create account</button></p>
					</form>
				</div>
			</div>

		</div>

	</section>

</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'AccountRegister',
	data() {
		return {
			state: 'idle',
			account: {
				name: '',
				email: '',
				password: '',
				plan: 'free',
			},
			error: '',
		}
	},
	created() {
		if (this.$route.query.plan && this.plans[this.$route.query.plan]) {
			this.account.plan = this.$route.query.plan
		}
	},
	methods: {
		register() {
			this.state = 'loading'

			this.$store.dispatch('accountRegister', this.account).then(() => {
				this.$router.push('/account/welcome')
			}, error => {
				this.state = 'error'
				this.error = error.response ? `${error.response.data.title} \n ${error.response.data.detail}`.replace("\n", '<br>') : error.message
			})

		}
	},
	computed: {
		...mapState([ 'plans' ]),
	}
}
</script>
