<template>

	<div class="section-account-info container py-6">
		<p class="text-center">
			<router-link to="/account" class="btn btn-outline-dark">← Account</router-link>
		</p>

		<div v-if="account" class="row justify-content-center">
			<div class="col-lg-6">
				<div class="card my-3">
					<div class="card-body">
						<h3 class="mb-4">Notifications</h3>

						<p>How should we notify you when we detect changes in domains?</p>

						<form @submit.prevent="accountUpdate">
							<div v-if="accountError" class="alert alert-danger">{{ accountError }}</div>

							<div class="row mb-3">
								<label for="notifications_where-email" class="col-sm-3 col-form-label">Where</label>
								<div class="col-sm-9">
									<div class="form-check form-switch">
										<input class="form-check-input" type="checkbox" id="notifications_where-email" checked>
										<label class="form-check-label" for="notifications_where-email">Email</label>
									</div>
									<div class="form-check form-switch">
										<input class="form-check-input" type="checkbox" id="notifications_where-slack" disabled>
										<label class="form-check-label" for="notifications_where-slack">Slack</label>
									</div>
								</div>
							</div>

							<div class="row mb-3">
								<label for="notifications_frequency" class="col-sm-3 col-form-label">Frequency</label>
								<div class="col-sm-9">
									<select required class="form-control" id="notifications_frequency" v-model="account.notifications_frequency">
										<option value="instant">Instant</option>
										<option value="daily">Daily</option>
										<option value="weekly">Weekly</option>
									</select>
									<small class="text-form text-muted">How often should we notify you?</small>
								</div>
							</div>

							<p class="text-center mt-4">
								<button class="btn btn-primary">Save</button>
							</p>
						</form>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="spinner-border" role="status"></div>
		</div>
	</div>

</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

export default {
	name: 'AccountNotifications',
	data() {
		return {
			accountError: '',
		}
	},
	created() {
		this.$store.dispatch('accountInfo')
	},
	computed: {
		...mapState([ 'account' ]),
	},
	methods: {
		accountUpdate() {
			this.accountError = ''

			this.$store.dispatch('accountUpdate', {
				notifications_frequency: this.account.notifications_frequency,
			}).catch(error => {
				if (error.response) {
					this.accountError = error.response.data.detail
					//alert(error.response.data.title)
				} else {
					Vue.toasted.error(`Error updating account: ${error}`)
				}
			})
		}
	}
}
</script>
