<template>

	<div class="section-account-info container py-6">
		<p class="text-center">
			<router-link to="/account" class="btn btn-outline-dark">← Account</router-link>
		</p>

		<div v-if="account" class="row justify-content-center">
			<div class="col-lg-6">
				<div class="card my-3">
					<div class="card-body">
						<h3 class="mb-4">Plan &amp; Billing</h3>

						<div v-if="!account.stripe_subscription_id">
							<p class="lead">You're not subscribed to a paid plan yet.</p>
							<p class="">Check our plans to monitor domains or access the API.</p>
							<p class="text-center"><router-link to="/plans" class="btn btn-primary px-5">Plans &amp; Pricing →</router-link></p>
						</div>
						<div v-else>
							<p>You're on <strong>{{ plans[account.plan].name }}</strong> plan.</p>

							<ul class="nicer-list">
								<li>Search domains <span class="text-success ms-2">✓</span></li>
								<li>Monitor domains <span class="ms-2">{{ account.domains }}/<span class="text-success">{{ account.domains_limit }}</span></span></li>
								<li>Domain WHOIS &amp; DNS Records <span class="text-success ms-2">✓</span></li>
								<li>Domain history <span class="text-success ms-2">✓</span></li>
								<li>Domain lists <span class="ms-2">-/<span class="text-success">{{ plans[account.plan].lists === 999999 ? '∞' : plans[account.plan].lists }}</span></span></li>
								<li>Domain &amp; WHOIS API <span class="text-success ms-2">{{ plans[account.plan].api ? '✓' : 'Ｘ' }}</span></li>
							</ul>

							<div class="row justify-content-between">
								<div class="col">
									<router-link to="/plans" class="btn btn-outline-primary m-2">All plans details</router-link>
								</div>
								<div class="col">
									<a :href="`${apiUrl}account/${account.stripe_customer_id}/stripe-customer-portal`" class="btn btn-primary m-2">Manage plan &amp; billing info</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="spinner-border" role="status"></div>
		</div>
	</div>

</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'AccountNotifications',
	data() {
		return {
			customerPortalUrl: null,
		}
	},
	computed: {
		...mapState([ 'account', 'apiUrl', 'plans' ]),
	},
	created() {
		this.$store.dispatch('ev', {
			event: 'View account Plan & Billing',
		})
	},
}
</script>
