<template>

	<section class="my-5 pt-4">

		<div class="container">

			<div class="row align-items-center">
				<div class="d-none d-md-flex col-md-7 px-5">
					<img src="@/assets/img-account.png" alt="Account" style="width: 70%">
				</div>

				<div class="col-md-5">

					<form @submit.prevent="login" class="bg-white rounded shadow-sm p-4">

						<h3>Sign in</h3>
						<p class="mb-4">or <router-link to="/account/register">create an account</router-link></p>

						<div v-if="error" class="alert alert-warning" role="alert">{{ error }}</div>

						<div class="mb-3">
							<label class="form-label" for="email">Email</label>
							<input type="email" class="form-control" id="email" v-model="account.email" placeholder="Your email" required>
						</div>

						<div class="mb-3">
							<label class="form-label" for="password">Password</label>
							<input type="password" class="form-control" id="password" v-model="account.password" placeholder="Your password" required minlength="6">
						</div>

						<div class="row align-items-center">
							<div class="col-auto">
								<button class="btn btn-primary" :disabled="state === 'loading'">Sign in</button>
							</div>
							<div class="col text-end">
								<router-link to="/account/reset-pass"><small>Forgot password?</small></router-link>
							</div>
						</div>

					</form>
					
				</div>
			</div>

		</div>
		
	</section>

</template>

<script>

export default {
	name: 'AccountLogin',
	data() {
		return {
			state: 'idle',
			account: {
				email: '',
				password: '',
			},
			error: '',
		}
	},
	methods: {
		login() {
			this.state = 'loading'

			this.$store.dispatch('authRequest', this.account).then(() => {
				this.$router.push(this.$route.query.redirect || '/')
			}, error => {
				this.state = 'error'
				this.error = error.response ? `${error.response.data.title}. ${error.response.data.detail}` : error.message
				console.error(error)
			})

		}
	},
}
</script>
