<template>
	<div class="page-account">

		<!--
		<section class="page-head no-bg">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-lg-8 my-6 text-center">
						<h1 class="display-5">Your Domains App account</h1>
						<p class="lead text-gray">Get all info about a domain directly from your browser</p>
					</div>
				</div>
			</div>
		</section>
		-->

		<router-view></router-view>

	</div>
</template>

<script>
export default {
	name: 'Account',
	metaInfo: {
      title: 'Your account'
    },
	created() {
		this.$store.dispatch('accountInfo')
		this.$store.dispatch('ev', {
			event: 'View Account',
		})
	}
}
</script>
