<template>

	<div class="section-account-info container py-6">

		<div class="row justify-content-center">
			<div class="col-sm-9 col-md-7 col-lg-6 col-xl-5">
				<div class="section-person-info text-center mb-4">
					<img class="rounded-circle shadow-sm mb-2" :src="`https://secure.gravatar.com/avatar/${md5(account.email)}?s=250&d=mm`" :alt="account.name" width="150" height="150" />
					<h1 class="display-5">{{ account.name }}</h1>
					<p class="lead">Plan: <strong class="text-capitalize">{{ account.plan }}</strong></p>
				</div>

				<div v-if="$route.query.a === 'email-confirmed'" class="alert alert-info alert-dismissible">
					Thanks for confirming your email, now we know for sure it's yours!
					<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
				</div>

				<div class="list-group">
					<router-link to="/account/update" class="list-group-item list-group-item-action">😀 Account info</router-link>
					<router-link to="/domains-database?monitoring=1" class="list-group-item list-group-item-action">
						<div class="row mb-1">
							<div class="col">
								📸 Domain watchlist
							</div>
							<div class="col-auto">
								<small class="float-end">{{ domainsMonitoring.length }}/{{ account.domains_limit }}</small>
							</div>
						</div>
						<div class="progress mb-1" style="height: 7px">
							<div class="progress-bar" role="progressbar" :style="{width: `${domainsMonitoring.length / account.domains_limit * 100}%`}"></div>
						</div>
					</router-link>
					<router-link to="/account/notifications" class="list-group-item list-group-item-action">🔔 Notifications</router-link>
					<router-link to="/account/plan-billing" class="list-group-item list-group-item-action">💸 Plan & billing</router-link>
					<router-link to="/account/api-keys" class="list-group-item list-group-item-action">🌩 API Keys</router-link>
					<router-link to="/account/logout" class="list-group-item list-group-item-action">🚪 Logout</router-link>
				</div>
			</div>
		</div>

	</div>

</template>

<script>
import { mapState } from 'vuex'
import md5 from 'md5'

export default {
	name: 'AccountInfo',
	data() {
		return {
			accountInfoEdited: false,
			accountError: '',
		}
	},
	created() {
		this.$store.dispatch('accountInfo')
	},
	computed: {
		...mapState([ 'domainsMonitoring', 'account' ]),
	},
	methods: {
		md5,
	}
}
</script>
