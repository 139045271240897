<template>

	<div class="section-account-info container py-6">
		<p class="text-center">
			<router-link to="/account" class="btn btn-outline-dark">← Account</router-link>
		</p>

		<div v-if="account" class="row justify-content-center">
			<div class="col-lg-6">
				<div class="card my-3">
					<div class="card-body">
						<h3 class="mb-4">Account info</h3>

						<form @submit.prevent="accountUpdate">
							<div v-if="accountError" class="alert alert-danger">{{ accountError }}</div>

							<div class="row mb-3">
								<label for="name" class="col-sm-3 col-form-label">Name</label>
								<div class="col-sm-9">
									<input type="text" required class="form-control" id="name" v-model="account.name">
								</div>
							</div>

							<div class="row mb-3">
								<label for="email" class="col-sm-3 col-form-label">Email</label>
								<div class="col-sm-9">
									<input type="email" required class="form-control" id="email" v-model="account.email">
									<small v-if="account.meta.newEmail" class="text-form">ℹ️ Please confirm your email <code>{{ account.meta.newEmail }}</code></small>
								</div>
							</div>

							<p class="text-center mt-4">
								<button class="btn btn-primary">Save account info</button>
							</p>
						</form>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="spinner-border" role="status"></div>
		</div>
	</div>

</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

export default {
	name: 'AccountUpdate',
	data() {
		return {
			accountError: '',
		}
	},
	created() {
		this.$store.dispatch('accountInfo')
	},
	computed: {
		...mapState([ 'account' ]),
	},
	methods: {
		accountUpdate() {
			this.accountError = ''

			this.$store.dispatch('accountUpdate', {
				name: this.account.name,
				email: this.account.email,
			}).catch(error => {
				if (error.response) {
					this.accountError = error.response.data.detail
					//alert(error.response.data.title)
				} else {
					Vue.toasted.error(`Error updating account: ${error}`)
				}
			})
		}
	}
}
</script>
