<template>

	<section class="my-5 pt-4">

		<div class="container">

			<div class="row align-items-center">
				<div class="d-none d-md-flex col-md-7 px-5">
					<img src="@/assets/img-account.png" alt="Account" style="width: 70%">
				</div>

				<div class="col-md-5">

					<form @submit.prevent="changePass" class="bg-white rounded shadow-sm p-4">

						<h3>Set a new password</h3>
						<p class="mb-4"><router-link to="/account/sign-in">← Sign in</router-link></p>

						<p>Enter a new password for your account.</p>

						<div v-if="error" class="alert alert-danger" role="alert">{{ error }}</div>

						<div class="form-group">
							<label for="password">Password</label>
							<input type="password" class="form-control" id="password" v-model="account.password" autocomplete="new-password" placeholder="Password with at least 6 characters" minlength="6" required>
						</div>

						<div class="form-group row align-items-center mb-0">
							<div class="col-auto">
								<button class="btn btn-primary" :disabled="state === 'loading'">Change password</button>
							</div>
						</div>

					</form>
					
				</div>
			</div>

		</div>
		
	</section>

</template>

<script>
import domainsApi from "@/api.js"
import Vue from 'vue'

export default {
	name: 'AccountChangePass',
	data() {
		return {
			state: 'idle',
			account: {
				token: null,
				password: '',
			},
			error: '',
		}
	},
	created() {
		if (this.$route.query.token) {
			this.account.token = this.$route.query.token
		} else {
			Vue.toasted.show('Invalid token for changing password', { type: 'error' })
			this.$router.push('/account/sign-in')
		}
	},
	methods: {
		changePass() {
			this.state = 'loading'

			domainsApi.post('account/new-pass', this.account)
				.then(() => {
					this.state = 'idle'

					Vue.toasted.show('Your password is changed, you can sign in', { type: 'success' })
					this.$router.push('/account/sign-in')
				})
				.catch(error => {
					this.state = 'error'

					if (error.response?.data?.title) {
						this.error = error.response.data.title
					} else {
						Vue.toasted.show(error.message, { type: 'error' })
					}
				})
		},
	},
}
</script>
